import './navbar.css';
// import Logo from './../../assets/logo.png';
import { SocialIcon } from "react-social-icons"
import logo from './../../assets/mmlogo.png';
require("dotenv").config();

export default function Nav() {

return(
  <div className="navbarwrapper">
 
      <div className="logowrapper">
      {/* <a href='https://metamountaineers.xyz/'> <img src={Logo} alt="Logo" className="logo" /> </a> */}
      <div className="logo"> <img src={logo} /> </div> 
      </div>
      <div className="nav-menu">
        <a className="nav-link" href='https://metamountaineers.notion.site/metamountaineers/Meta-Mountaineers-Whitepaper-7675283bc6154cef9c34abec6f54c92a'>
          About
        </a>
        <div className="social-icon-wrapper">
          <SocialIcon url="https://twitter.com/metamountaineer" bgColor="white" style={{ height: 30, width: 30 }}/>
        
        </div>
        <div className="social-icon-wrapper">

          <SocialIcon url="http://discord.gg/metamountaineers" bgColor="white" style={{ height: 30, width: 30 }} />
        </div>
       

      </div>
    
    </div>
);
}
