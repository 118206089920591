import logo from './logo.svg';
import './App.css';
import Nav from './components/navbar/navbar';
import MintPage from './components/mintpage/mintpage';
import Hero from './components/Homepage/homepage';
import About from './components/AboutPage/about';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <> 
    <Nav />
    <BrowserRouter>
    <Routes>
          <Route path="/" element={<MintPage /> } />
          <Route path="/home" element={<Hero />} />
          <Route path="/about" element={<About />} />
    </Routes>


    </BrowserRouter>
    </>
  );
}

export default App;
