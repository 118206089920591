import './homepage.css'


export default function Hero() {
  return (
    <div className='hero-section'>
        <h1>Coming Soon </h1>
        <p>coming soon</p>

    </div>

  );
}
