import './about.css'


export default function About() {
  return (
    <div className='hero-section'>
        <h1>Coming Soon</h1>
        <p>coming soon</p>


    </div>
  );
}
