import { React, useState, useEffect } from "react";
import Web3 from "web3";
import abi from "./abi.json";
import './mintpage.css';
import nft from './../../assets/trail-acc-pass.gif';
require("dotenv").config();
const { REACT_APP_CONTRACT_ADDRESS } = process.env;


const MintPage = ({ connecctstatus, setConnectedstatus }) => {
  const [connectedAccount, setConnectedAccount] = useState("CONNECT");
  const [whitelisted, setwhitelisted] = useState(false);
  const [contract, setContract] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [supply, setTokenSupply] = useState(null);
  const [price, setPrice] = useState();
  const [priceInEth, setPriceInEth] = useState(0.08);
  const [quantity, setQuantity] = useState(1);
  const [minted, setMinted] = useState(false);
  console.log("C", connecctstatus);

  useEffect(() => {
    loadWeb3();
  }, []);

  async function loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      // await window.ethereum.enable();
      const web3 = window.web3;
      // creating contract instance
      const contractaddress = "0x7E9b13Bb095Ec33023ddc2ffb98E8606Bf684184";
      const ct = new web3.eth.Contract(abi, contractaddress);
      setContract(ct);
      console.log("contract", ct);
      let price = await ct.methods.cost().call();
      setContract(ct);
      
      setPrice(price);
      console.log(price);
      setPriceInEth(web3.utils.fromWei(price, "ether"));
      const supply = await ct.methods.maxSupply().call();
      setTokenSupply(supply);
      const minted = await ct.methods.totalSupply().call();
      setMinted(minted);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }
  async function mint() {
    const web3 = window.web3;
    const _value = price * quantity;
    const address = await web3.eth.getAccounts();
    await contract.methods
      .mint(quantity)
      .send({ from: address.toString(), value: _value });
    setMinted(true);
    const totalSupply = await contract.methods.maxSupply().call();
    setTokenSupply(totalSupply);
  }

  async function connectWallet() {

    const whitelistedaddresses = ['0x134855a52C0668a51595C13F3049617B278f9Fb6',
                                '0x03daf436135d186a4dfd3a834757c3f34ea273bc',
                                '0x03daf436135d186a4dfh7a834757c3f34ea243bc',
                                '0x07daf436135db86a4dfd2a834757c3f94ea273bc'];

    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      const metaMaskAccount = await web3.eth.getAccounts();
      console.log("wallet address" ,metaMaskAccount[0])
      let whitelist=whitelistedaddresses.indexOf(metaMaskAccount[0]);
      console.log("is whitelisted", whitelist);
      // setConnectedstatus(true);
      let splitedMetaMaskAddress;
      if (metaMaskAccount ) {
        if(whitelist != -1){
        splitedMetaMaskAddress="MINT";
        setConnectedAccount(splitedMetaMaskAddress);
        setwhitelisted(true);
        }
        //   metaMaskAccount[0].substring(0, 6) +
        //   "......" +
        //   metaMaskAccount[0].substring(
        //     metaMaskAccount[0].length - 4,
        //     metaMaskAccount[0].length
        //   );
        
        
      
      else{
          splitedMetaMaskAddress="USER NOT WHITELISTED";
          setConnectedAccount(splitedMetaMaskAddress);
          console.log(splitedMetaMaskAddress);
      }
    }
      // setConnectedAccount(splitedMetaMaskAddress);
    } 
     
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  async function wrapperFunction(){
    
      if(whitelisted){
       mint();
      }
    }

    return (  
        <div className="mintpagewrapper">
                <div className="Heading"> Trail Access Pass</div>
            
            <div className="nftimage"> <img src={nft} /> </div>
            <h1 className="minted-nfts">
                Minted: {minted} / {supply}
              </h1>
            <div className="quantity-selector">
                <button
                  type="button"
                className="quantity-button"
                  onClick={() => {
                    if (quantity > 1) {
                      setQuantity(quantity - 1);
                    }
                  }}
                >
                  -
                </button>
                <button type="button" className="quantity-button">
                  {quantity}
                </button>
                <button
                  type="button"
                  className="quantity-button"
                >
                  +
                </button>
        
        
        
        </div>
        <button className="mintbutton" onClick={async () => {
                  await connectWallet();
                  await wrapperFunction();
                }}
                > {connectedAccount}</button>

                
</div>
    );
              }



export default MintPage;